import { Polygon, useCall, useContractFunction, useEtherBalance, useTokenBalance } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";
import SaleABI from "./abi/SaleABI.json";

export const usedNetwork = {
  chain: Polygon,
  rpc:
    "https://polygon-mainnet.g.alchemy.com/v2/GjRWUWTuYcN8uimHjkaHz2SvtE2K_RpV",
};

export const SaleContract = new Contract(
  "0x7dc713427BCB6a110fddAF29051EE078A92457EF",
  SaleABI,
);

export const useOwner = () => {
  const { value } = useCall({
    contract: SaleContract,
    args: [],
    method: "owner",
  }) ?? { value: undefined, error: undefined };

  if (!value) return undefined;

  return value[0];
};

export const useTokenSaleBalance = () => {
  const balance = useEtherBalance(SaleContract.address) || BigNumber.from(0);
  return balance;
};

export const useOutsidePurchase = () => {
  const { send } = useContractFunction(
    SaleContract,
    "sendOutsidePurchase",
  );
  return (amount: BigNumber, beneficiary: string) => send(amount, beneficiary);
};

export const useWithdraw = () => {
  const { state, send } = useContractFunction(SaleContract, "withdraw");
  return { withdraw: send, withdrawState: state };
};

export const useWithdrawUSDT = () => {
  const balance = useTokenBalance("0xc2132D05D31c914a87C6611C10748AEb04B58e8F", SaleContract.address);

  const { state, send } = useContractFunction(SaleContract, "withdrawUSDT");
  return { withdrawUSDTState: state, balanceUSDT: balance, withdrawUSDT: () => send(balance) };
};

export const usePauseUnpause = () => {
  const { value } = useCall({
    contract: SaleContract,
    args: [],
    method: "paused",
  }) ?? { value: undefined, error: undefined };

  const { send: unpause } = useContractFunction(SaleContract, "unpause");
  const { send: pause } = useContractFunction(SaleContract, "pause");

  if (!value) return { paused: undefined, changePaused: () => { } };

  const paused = value[0];

  return {
    paused,
    changePaused: paused ? unpause : pause,
  };
};

export const useExtraTokens = () => {
  const { value } = useCall({
    contract: SaleContract,
    args: [],
    method: "extraTokensNumerator",
  }) ?? { value: undefined, error: undefined };

  const { send: setExtraTokens } = useContractFunction(
    SaleContract,
    "setExtraTokens",
  );

  if (!value) return { extraTokens: undefined, setExtraTokens: () => { } };

  const extraTokens = value[0] as BigNumber;
  return { extraTokens, setExtraTokens };
};

/**
 * @returns {BigNumber} tokens per MATIC
 */
export const useCurrentPrice = () => {
  const { value, error } = useCall({
    contract: SaleContract,
    args: [],
    method: "tokenPrice",
  }) ?? { value: undefined, error: undefined };

  if (error) console.error(error);

  if (!value) return "loading";
  const amount = value[0] as BigNumber;
  return amount.toString();
};

export const useSetPrice = () => {
  const { state, send } = useContractFunction(SaleContract, "setTokenPrice");
  return { setPrice: send, setPriceState: state };
};
