import { useState } from "react";
import { useLogin } from "./LoginProvider";

export default function LoginBlock({ children }: { children: React.ReactNode; }) {
  const { logIn, loggedIn } = useLogin();
  const [password, setPassword] = useState("");

  if (loggedIn) {
    return <>{children}</>;
  } else {
    return <div className="w-[100vw] h-[100vh] overflow-hidden flex flex-col items-center justify-center">
      <div className="flex flex-col gap-4 p-16 bg-gray-200 rounded-xl">
        <h1 className="text-lg font-bold mb-4">Log in to the admin panel</h1>
        <form className="flex flex-row gap-4" onSubmit={e => { e.preventDefault(); logIn(password); }}>
          <input type="password" placeholder="Password" className="py-1 px-2" value={password} onChange={e => setPassword(e.target.value)} />
          <input type="submit" className="flex flex-row items-center justify-center py-1 px-2 bg-slate-400 rounded-lg" value="Log in" />
        </form>
      </div>
    </div>;
  }
}