type ButtonProps = JSX.IntrinsicElements["button"];//typeof Button.defaultProps & React.PropsWithChildren<{}>;

const Button: React.FC<ButtonProps> = (props) => {
    return (
        <button {...{
            ...props,
            className: `bg-gray-500 rounded-md px-2 ${props.className}`
        }} />
    );
};

export default Button;