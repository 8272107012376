import { useCall, useContractFunction, useEtherBalance, useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";
import { formatEther, isAddress } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import NFTSaleABI from "./abi/NFTSaleABI.json";
import NFTABI from "./abi/NFTABI.json";
import { usedNetwork } from "./tokenConstants";

export const nftAddress = "0x9c8A30F7dBC97951104fAFCfe85C9c50A7ECB34F";
export const nftSaleAddress = "0xCC89bB34323463199F08Ca5C55ffc6f1c3e92d96";

export const NFTSaleContract = new Contract(nftSaleAddress, NFTSaleABI);
export const NFTContract = new Contract(nftAddress, NFTABI);

export const useWithdraw = () => {
  const { state, send } = useContractFunction(NFTSaleContract, "withdraw");
  return { withdraw: send, withdrawState: state };
};

/**
 * airdrop(address,uint256)
 */
export const useAirdrop = () => {
  const { state, send } = useContractFunction(NFTSaleContract, "airdrop");
  return { airdrop: send, airdropState: state };
};

export const useNFTSaleBalance = () => {
  const balance = useEtherBalance(NFTSaleContract.address) || BigNumber.from(0);
  return balance;
}

export const usePauseUnpause = () => {
  const { value } = useCall({
    contract: NFTSaleContract,
    args: [],
    method: "paused",
  }) ?? { value: undefined, error: undefined };

  const { send: unpause } = useContractFunction(NFTSaleContract, "unpause");
  const { send: pause } = useContractFunction(NFTSaleContract, "pause");

  if (!value) return { paused: undefined, changePaused: () => {} };

  const paused = value[0];

  return {
    paused,
    changePaused: paused ? unpause : pause,
  };
};

/**
 * @returns {BigNumber} tokens per MATIC
 */
export const useCurrentPrice = () => {
  const { value, error } = useCall({
    contract: NFTSaleContract,
    args: [],
    method: "price",
  }) ?? { value: undefined, error: undefined };

  if (error) console.error(error);

  if (!value || !BigNumber.isBigNumber(value[0])) return "loading";
  return formatEther(value[0]);
};

export const useSetPrice = () => {
  const { state, send } = useContractFunction(NFTSaleContract, "setSalePrice");
  return { setPrice: send, setPriceState: state };
};

export const useAuction = () => {
  const isLive = useAuctionLive();

  const startDateRaw = useNFTCall("startDate");
  const endDateRaw = useNFTCall("endDate");
  const startAuction = useNFTFunction("startAuction");
  const stopAuction = useNFTFunction("stopAuction");

  const [bidder, amount, time] = useNFTCall("highestBid") ??
    [undefined, undefined, undefined];

  const startDate = startDateRaw ? new Date(startDateRaw[0] * 1000) : undefined;
  const endDate = endDateRaw ? new Date(endDateRaw[0] * 1000) : undefined;
  const bidDate = time ? new Date(time[0] * 1000) : undefined;

  return {
    isLive,
    startDate,
    endDate,
    highestBid: {
      bidder: isAddress(bidder) ? bidder : undefined,
      amount: BigNumber.isBigNumber(amount) ? amount : undefined,
      date: bidDate,
    },
    startAuction,
    stopAuction,
  };
};

export const useExists = (id: number) => {
  const value = useNFTCall("exists", [id], NFTContract);
  return !!value?.[0];
};

const useNFTCall = (method: string, args?: any[], contract?: Contract) => {
  const { value } = useCall({
    contract: contract || NFTSaleContract,
    args: args || [],
    method,
  }) ?? { value: undefined, error: undefined };

  return value;
};

const useNFTFunction = (method: string, contract?: Contract) => {
  const { send } = useContractFunction(
    contract || NFTSaleContract,
    method,
  );
  return send;
};

const useAuctionLive = () => {
  const [live, setLive] = useState<boolean | "loading">("loading");

  const { library, account } = useEthers();

  useEffect(() => {
    if (!library || !account) return;

    checkAuctionLive(library, account)
      .then((res: boolean) => setLive(res));
  }, [library, account]);

  return live;
};

type Provider = NonNullable<ReturnType<typeof useEthers>["library"]>;

const checkAuctionLive = async (library: Provider, address: string) => {
  if (!library) return;

  const network = await library.ready;
  // Only check on the correct network
  if (network.chainId !== usedNetwork.chain.chainId) {
    return false;
  }

  const signer = library.getSigner(address);

  try {
    const status = await NFTSaleContract.connect(signer)
      .checkAuction();
    console.log("got live status", status);
    return status;
  } catch (e) {
    console.log("got wrong live status");
    return false;
  }
};
