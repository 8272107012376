import { constants, utils } from "ethers";
import { useState } from "react";
import { useOutsidePurchase, usePauseUnpause } from "../util/tokenConstants";
import Button from "./Button";
import { BtnCol, BtnRow, Section } from "./Container";

const TokenAirdropSection = () => {
  const { paused } = usePauseUnpause();
  const airdrop = useOutsidePurchase();

  const [receiverAddress, setReceiverAddress] = useState("");
  const [amount, setAmount] = useState<string>("");

  return (
    <Section>
      <p className='text-2xl'><strong>XVRcoin Airdrops</strong></p>

      <BtnCol>
        Give tokens:
        <input
          value={receiverAddress}
          onChange={(e) => { setReceiverAddress(e.target.value); }}
          type="text"
          placeholder="Receiver address"
          className="text-black w-full text-sm"
        />
        <BtnRow>
          <span>Amount</span>
          <input
            type="number"
            value={amount}
            onChange={e => setAmount(e.target.value)}
            className="text-black w-full text-sm"
            placeholder="10000"
          />
          <span>XVRC</span>
        </BtnRow>

        <Button onClick={() => {
          if (!amount || isNaN(parseInt(amount))) {
            alert("Amount is required");
            return;
          }

          if (!utils.isAddress(receiverAddress)) {
            alert("Receiver address is required");
            return;
          }

          let amountBn = constants.Zero;

          try {
            amountBn = utils.parseEther(amount);
          } catch (e) {
            alert("Amount is invalid");
            return;
          }

          airdrop(amountBn, receiverAddress);
        }}>
          {paused ? <>
            <span className="line-through">Send</span> (paused)
          </> : <>
            Send
          </>}
        </Button>
      </BtnCol>
    </Section>
  );
};

export default TokenAirdropSection;
