import { utils } from "ethers";
import { useState } from "react";
import { airdropNfts } from "../util/airdrop";
import { useAirdrop, useExists, usePauseUnpause } from "../util/nftContstants";
import Button from "./Button";
import { BtnCol, BtnRow, Section } from "./Container";

const NFTAirdropSection = () => {
  const { paused } = usePauseUnpause();
  const { airdrop } = useAirdrop();

  const [receiverAddress, setReceiverAddress] = useState("");
  const [tokenId, setTokenId] = useState<string>("-- select --");

  return (
    <Section>
      <p className='text-2xl'><strong>XVR NFT Airdrops</strong></p>

      <BtnCol>
        Give NFT:
        {/* withdraw(xvr, partner25, partner20, nftFeePartner) */}
        <input
          value={receiverAddress}
          onChange={(e) => { setReceiverAddress(e.target.value); }}
          type="text"
          placeholder="Receiver address"
          className="text-black w-full text-sm"
        />
        <BtnRow>
          <select value={tokenId} onChange={e => setTokenId(e.target.value)} className="text-black px-1">
            <option value="-- select --" disabled>-- select --</option>
            {Object.keys(airdropNfts).map((key) => (
              <TokenId key={key} id={key} />
            ))}
          </select>
          <input
            type="number"
            value={tokenId}
            onChange={e => setTokenId(e.target.value)}
            className="text-black w-full text-sm"
            placeholder="Token ID (optional)"
          />
        </BtnRow>

        <Button onClick={() => {
          if (!tokenId || isNaN(parseInt(tokenId))) {
            alert("Token ID is required");
            return;
          }

          if (!utils.isAddress(receiverAddress)) {
            alert("Receiver address is required");
            return;
          }

          airdrop(receiverAddress, tokenId);
        }}>
          {paused ? <>
            <span className="line-through">Airdrop #{tokenId}</span> (paused)
          </> : <>
            Airdrop #{tokenId}
          </>}
        </Button>
      </BtnCol>
    </Section>
  );
};

export default NFTAirdropSection;

const TokenId: React.FC<{ id: string; }> = ({ id }) => {
  const idNum = parseInt(id);

  const exists = useExists(idNum);

  return (
    <option value={id} disabled={exists}>{id}</option>
  );
};