import { formatEther, parseEther } from "ethers/lib/utils";
import { useState } from "react";
import { useCurrentPrice, useNFTSaleBalance, usePauseUnpause, useSetPrice, useWithdraw } from "../util/nftContstants";
import Button from "./Button";
import { BtnCol, BtnRow, Col, Label, Section } from "./Container";

const NFTSaleSection = () => {
    const { paused, changePaused } = usePauseUnpause();
    const price = useCurrentPrice();
    const { setPrice } = useSetPrice();
    const { withdraw } = useWithdraw();

    const [newPrice, setNewPrice] = useState("");

    const [xvrAddress, setXvrAddress] = useState("");
    const [partner25Address, setPartner25Address] = useState("");
    const [partner20Address, setPartner20Address] = useState("");
    const [nftFeePartnerAddress, setNftFeePartnerAddress] = useState("");

    const saleBalance = useNFTSaleBalance();

    return (
        <Section>
            <p className='text-2xl'><strong>XVR NFT Sale</strong></p>

            <BtnCol>
                <p>Withdraw MATIC from the sale:</p>
                <p>You will get: {formatEther(saleBalance)} MATIC</p>
                {/* withdraw(xvr, partner25, partner20, nftFeePartner) */}
                <input
                    value={xvrAddress}
                    onChange={(e) => { setXvrAddress(e.target.value); }}
                    type="text"
                    placeholder="XVR address"
                    className="text-black w-full text-sm"
                />
                <input
                    value={partner25Address}
                    onChange={(e) => { setPartner25Address(e.target.value); }}
                    type="text"
                    placeholder="25% partner address"
                    className="text-black w-full text-sm"
                />
                <input
                    value={partner20Address}
                    onChange={(e) => { setPartner20Address(e.target.value); }}
                    type="text"
                    placeholder="20% partner address"
                    className="text-black w-full text-sm"
                />
                <input
                    value={nftFeePartnerAddress}
                    onChange={(e) => { setNftFeePartnerAddress(e.target.value); }}
                    type="text"
                    placeholder="NFT 4% fee partner address"
                    className="text-black w-full text-sm"
                />
                <Button onClick={() => withdraw(xvrAddress, partner25Address, partner20Address, nftFeePartnerAddress)}>
                    {paused ? <>
                        <span className="line-through">Withdraw</span> (paused)
                    </> : <>
                        Withdraw
                    </>}
                </Button>
            </BtnCol>

            <hr />

            <BtnRow>
                Sale paused: {paused ? 'Yes' : 'No'}
                <Button onClick={() => changePaused()}>{paused ? "Start" : "Pause"}</Button>
            </BtnRow>

            <hr />

            <Col>
                <BtnRow>Current price: {price} USD</BtnRow>
                <BtnCol>
                    <Label>
                        1 NFT =
                        <input
                            value={newPrice}
                            onChange={(e) => { setNewPrice(e.target.value); }}
                            type="number"
                            placeholder={price}
                            className="text-black"
                        />
                        USD
                    </Label>

                    <Button onClick={() => {
                        if (newPrice === "" || isNaN(parseFloat(newPrice)) || parseFloat(newPrice) <= 0) {
                            alert("Please enter a valid price");
                            return;
                        };

                        try {
                            const newPriceWei = parseEther(newPrice);
                            setPrice(newPriceWei);
                        } catch (e) {
                            alert("Please enter a valid price");
                            return;
                        }
                    }}>Set new price</Button>
                </BtnCol>
            </Col>
        </Section>
    );
};

export default NFTSaleSection;