import { useEthers } from "@usedapp/core";
import { splitSignature } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { callApi } from "../util/api";
import { getIsAutowithdrawEnabled } from "../util/config";
import Button from "./Button";
import { BtnCol, BtnRow, Section } from "./Container";

const ConfigSection = () => {
  const [enabled, setEnabled] = useState(false);
  const { library } = useEthers();

  useEffect(() => {
    getIsAutowithdrawEnabled()
      .then((enabled) => setEnabled(enabled));
  }, []);

  return (
    <Section>
      <p className='text-2xl'><strong>Configuration</strong></p>

      <BtnCol>
        <BtnRow>
          Auto-withdraw enabled: {enabled ? "Yes" : "No"}
        </BtnRow>
        <Button onClick={async () => {
          const message = `I want to ${!enabled ? "enable" : "disable"} autowithdraw`;
          let signature: string = "";

          try {
            signature = await library?.getSigner().signMessage(message) || "";
          } catch (e) {
            console.error(e);
          }

          const { r, s, v } = splitSignature(signature);

          callApi(
            "/config",
            "POST",
            {
              enabled: !enabled,
              r,
              s,
              v,
            }
          )
            .then(() => window.location.reload())
            .catch((e) => console.error(e));
        }}>
          {enabled ? "Disable" : "Enable"}
        </Button>
      </BtnCol>
    </Section>
  );
};

export default ConfigSection;
