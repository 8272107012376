import { callApi } from "./api";

export async function getIsAutowithdrawEnabled() {
  const data = await callApi(
    "/config",
    "GET",
  );

  return !!data.enabled;
}
