/**
 * Ids of NFTs that will be airdropped to users instead of sold
 */
export const airdropNfts: {
  [key: string]: boolean | undefined;
} = {
  "10": true,
  "12": true,
  "14": true,
  "20": true,
  "22": true,
  "23": true,
  "26": true,
  "28": true,
  "37": true,
  "40": true,
  "100": true,
  "109": true,
  "112": true,
  "120": true,
  "151": true,
  "166": true,
  "175": true,
  "177": true,
  "179": true,
  "183": true,
  "186": true,
  "188": true,
  "197": true,
  "211": true,
  "223": true,
  "235": true,
  "240": true,
  "247": true,
  "253": true,
  "261": true,
  "271": true,
  "280": true,
  "286": true,
  "291": true,
  "295": true,
  "301": true,
  "307": true,
  "331": true,
  "343": true,
  "348": true,
  "360": true,
  "391": true,
  "418": true,
  "424": true,
  "426": true,
  "432": true,
  "436": true,
  "452": true,
  "472": true,
  "496": true,
  "510": true,
  "529": true,
  "532": true,
  "537": true,
  "544": true,
  "547": true,
  "553": true,
  "559": true,
  "565": true,
  "582": true,
  "598": true,
  "599": true,
  "601": true,
  "604": true,
  "627": true,
  "648": true,
  "664": true,
  "670": true,
  "691": true,
  "705": true,
  "722": true,
  "727": true,
  "766": true,
  "769": true,
  "772": true,
  "780": true,
  "787": true,
  "808": true,
  "821": true,
  "844": true,
  "865": true,
  "867": true,
  "874": true,
  "876": true,
  "877": true,
  "879": true,
  "886": true,
  "925": true,
  "927": true,
  "931": true,
  "946": true,
  "952": true,
  "961": true,
  "970": true,
  "982": true,
  "988": true,
  "1015": true,
  "1020": true,
  "1036": true,
  "1042": true,
  "1051": true,
  "1057": true,
  "1058": true,
  "1065": true,
  "1096": true,
  "1123": true,
  "1126": true,
  "1136": true,
  "1165": true,
  "1168": true,
  "1180": true,
  "1208": true,
  "1210": true,
  "1219": true,
  "1231": true,
  "1235": true,
  "1236": true,
  "1240": true,
  "1260": true,
  "1267": true,
  "1311": true,
  "1312": true,
  "1322": true,
  "1333": true,
  "1369": true,
  "1381": true,
  "1387": true,
  "1402": true,
  "1408": true,
  "1414": true,
  "1416": true,
  "1418": true,
  "1422": true,
  "1426": true,
  "1428": true,
  "1433": true,
  "1435": true,
  "1438": true,
  "1458": true,
  "1471": true,
  "1477": true,
  "1483": true,
  "1484": true,
  "1509": true,
  "1513": true,
  "1519": true,
  "1527": true,
  "1537": true,
  "1549": true,
  "1561": true,
  "1571": true,
  "1586": true,
  "1612": true,
  "1616": true,
  "1621": true,
  "1627": true,
  "1639": true,
  "1681": true,
  "1689": true,
  "1693": true,
  "1696": true,
  "1702": true,
  "1708": true,
  "1774": true,
  "1782": true,
  "1798": true,
  "1801": true,
  "1819": true,
  "1855": true,
  "1873": true,
  "1876": true,
  "1879": true,
  "1880": true,
  "1882": true,
  "1884": true,
  "1897": true,
  "1900": true,
  "1912": true,
  "1915": true,
  "1957": true,
  "1958": true,
  "1963": true,
  "1988": true,
  "1996": true,
  "2020": true,
  "2055": true,
  "2095": true,
  "2101": true,
  "2114": true,
  "2124": true,
  "2128": true,
  "2141": true,
  "2145": true,
  "2148": true,
  "2152": true,
  "2154": true,
  "2158": true,
  "2160": true,
  "2162": true,
  "2172": true,
  "2176": true,
  "2203": true,
  "2213": true,
  "2215": true,
  "2221": true,
  "2227": true,
  "2233": true,
  "2243": true,
  "2245": true,
  "2257": true,
  "2275": true,
  "2284": true,
  "2323": true,
  "2338": true,
  "2346": true,
  "2350": true,
  "2360": true,
  "2377": true,
  "2386": true,
  "2389": true,
  "2401": true,
  "2437": true,
  "2440": true,
  "2455": true,
  "2473": true,
  "2481": true,
  "2491": true,
  "2498": true,
  "2500": true,
  "2502": true,
  "2503": true,
  "2509": true,
  "2565": true,
  "2569": true,
  "2584": true,
  "2589": true,
  "2592": true,
  "2593": true,
  "2644": true,
  "2647": true,
  "2653": true,
  "2674": true,
  "2675": true,
  "2683": true,
  "2689": true,
  "2700": true,
  "2707": true,
  "2709": true,
  "2711": true,
  "2716": true,
  "2719": true,
  "2722": true,
  "2726": true,
  "2729": true,
  "2733": true,
  "2736": true,
  "2739": true,
  "2741": true,
  "2747": true,
  "2748": true,
  "2776": true,
  "2780": true,
  "2781": true,
  "2788": true,
  "2795": true,
  "2805": true,
  "2815": true,
  "2833": true,
  "2845": true,
  "2857": true,
  "2881": true,
  "2893": true,
  "2920": true,
  "2925": true,
  "2938": true,
  "2951": true,
  "2953": true,
  "2956": true,
  "2965": true,
  "3000": true,
  "3003": true,
  "3004": true,
  "3025": true,
  "3037": true,
  "3040": true,
  "3043": true,
  "3052": true,
  "3078": true,
  "3086": true,
  "3088": true,
  "3100": true,
  "3103": true,
  "3106": true,
  "3145": true,
  "3166": true,
  "3179": true,
  "3181": true,
  "3185": true,
  "3187": true,
  "3191": true,
  "3210": true,
  "3217": true,
  "3229": true,
  "3234": true,
  "3247": true,
  "3281": true,
  "3289": true,
  "3308": true,
  "3317": true,
  "3324": true,
  "3331": true,
  "3340": true,
  "3353": true,
  "3374": true,
  "3381": true,
  "3399": true,
  "3402": true,
  "3406": true,
  "3412": true,
  "3413": true,
  "3416": true,
  "3421": true,
  "3423": true,
  "3424": true,
  "3427": true,
  "3429": true,
  "3433": true,
  "3435": true,
  "3438": true,
  "3439": true,
  "3441": true,
  "3445": true,
  "3474": true,
  "3477": true,
  "3486": true,
  "3490": true,
  "3498": true,
  "3502": true,
  "3509": true,
  "3520": true,
  "3526": true,
  "3547": true,
  "3551": true,
  "3556": true,
  "3565": true,
  "3577": true,
  "3598": true,
  "3603": true,
  "3624": true,
  "3625": true,
  "3630": true,
  "3631": true,
  "3640": true,
  "3697": true,
  "3716": true,
  "3718": true,
  "3721": true,
  "3732": true,
  "3751": true,
  "3754": true,
  "3766": true,
  "3776": true,
  "3778": true,
  "3786": true,
  "3790": true,
  "3792": true,
  "3796": true,
  "3799": true,
  "3802": true,
  "3804": true,
  "3805": true,
  "3819": true,
  "3820": true,
  "3826": true,
  "3837": true,
  "3841": true,
  "3843": true,
  "3847": true,
  "3853": true,
  "3859": true,
  "3870": true,
  "3877": true,
  "3889": true,
  "3895": true,
  "3901": true,
  "3904": true,
  "3910": true,
  "3913": true,
  "3928": true,
  "3937": true,
  "3940": true,
  "3941": true,
  "3946": true,
  "3949": true,
  "3955": true,
  "3958": true,
  "3961": true,
  "3964": true,
  "3970": true,
  "3988": true,
};
