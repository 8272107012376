const URL = "https://us-central1-xvr-polygon.cloudfunctions.net/widgets";

export const callApi = async (url: string, method = "POST", data?: any) => {
  const response = await fetch(
    `${URL}${url}`,
    {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    console.log(response.ok, response.status, response.statusText);
    throw new Error(response.status.toString());
  }

  return await response.json();
};
