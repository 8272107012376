import { createContext, useCallback, useContext, useEffect, useState } from "react";

interface LoginContextT {
  loggedIn: boolean;
  logIn: (password: string) => void;
}

const LoginContext = createContext<LoginContextT>({
  loggedIn: false,
  logIn: () => { }
});

export default function LoginProvider({ children }: { children: React.ReactNode; }) {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const prev = localStorage.getItem("login.loggedin");
    if (prev && prev === "true") {
      setLoggedIn(true);
    }
  }, []);

  const logIn = useCallback((password: string) => {
    if (password === "1234qwerty") {
      setLoggedIn(true);
      localStorage.setItem("login.loggedin", "true");
    } else {
      alert("Bad password");
    }
  }, []);

  return (
    <LoginContext.Provider
      value={{
        loggedIn,
        logIn
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export function useLogin() {
  return useContext(LoginContext);
}