import React from "react";

type SectionT = React.FC<React.PropsWithChildren<{
    className?: string;
}>>;

const BtnRow: SectionT = ({ children, className = "" }) => {
    return (
        <div className={`flex items-center gap-2 ${className}`}>
            {children}
        </div>
    );
};

const BtnCol: SectionT = ({ children, className = "" }) => {
    return (
        <div className={`flex flex-col items-start gap-4 ${className}`}>
            {children}
        </div>
    );
};

const Col: SectionT = ({ children, className = "" }) => {
    return (
        <div className={`flex flex-col gap-4 ${className}`}>
            {children}
        </div>
    );
};

const Section: SectionT = ({ children, className = "" }) => {
    return (
        <Col className="w-[400px] max-w-[95vw] py-4 px-2 sm:px-4 m-4 border-[1px] rounded-xl">
            {children}
        </Col>
    );
};

const Label: SectionT = ({ children, className = "" }) => {
    return (
        <label className={`flex flex-row justify-between items-center min-w-[70%] gap-2 ${className}`}>
            {children}
        </label>
    );
};



export { BtnRow, BtnCol, Col, Section, Label };