import { useEthers } from '@usedapp/core';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { useState } from 'react';
import AffiliateLinkSection from './components/AffiliateLinkSection';
import BiAffiliateLinkSection from './components/BiAffiliateLinkSection';
import Button from './components/Button';
import ConfigSection from './components/ConfigSection';
import { BtnCol, BtnRow, Col, Label, Section } from './components/Container';
import NFTAffiliateLinkSection from './components/NFTAffiliateLinkSection';
import NFTAirdropSection from './components/NFTAirdrop';
import NFTSaleSection from './components/NFTSaleSection';
import TokenAirdropSection from './components/TokenAirdrop';
import './index.css';
import { useCurrentPrice, useExtraTokens, useOwner, usePauseUnpause, useSetPrice, useTokenSaleBalance, useWithdraw, useWithdrawUSDT } from './util/tokenConstants';

function App() {
  const { activateBrowserWallet, account, deactivate } = useEthers();

  const owner = useOwner();
  const { withdraw } = useWithdraw();
  const { withdrawUSDT, balanceUSDT } = useWithdrawUSDT();
  const { paused, changePaused } = usePauseUnpause();
  const price = useCurrentPrice();
  const { setPrice } = useSetPrice();
  const { extraTokens, setExtraTokens } = useExtraTokens();

  const [newPriceUSD, setNewPriceUSD] = useState("");
  const [extraTokensInput, setExtraTokensInput] = useState("");

  const saleBalance = useTokenSaleBalance();

  return (
    <div className="text-center">
      <div className="bg-primary min-h-screen py-[100px] flex flex-col items-center justify-center text-white gap-4">
        <section>
          <a
            className="text-secondary text-xl"
            href="/"
            onClick={account ? deactivate : activateBrowserWallet}
          >
            {account ? "Disconnect wallet" : "Connect wallet"}
          </a>
          {account && owner && <p style={{
            color: account === owner ? "lightgreen" : "red"
          }}>
            {account === owner ? "You are the owner" : "You are not the owner, please connect a different wallet"}
          </p>}
        </section>

        <header><p className='text-2xl'>Admin panel</p></header>

        <Section>
          <p className='text-2xl'><strong>XVR Token</strong></p>

          <BtnCol className='gap-0'>
            <p>You will get: {formatEther(saleBalance)} MATIC</p>
            <BtnRow>
              <p>Withdraw MATIC from the sale:</p>
              <Button onClick={() => withdraw()}>Withdraw</Button>
            </BtnRow>
          </BtnCol>

          <hr />

          <BtnCol className='gap-0'>
            <p>You will get: {formatUnits(balanceUSDT ?? 0, 6)} USDT</p>
            <BtnRow>
              <p>Withdraw USDT from the sale:</p>
              <Button onClick={() => withdrawUSDT()}>Withdraw</Button>
            </BtnRow>
          </BtnCol>

          <hr />

          <BtnRow>
            Sale paused: {paused ? "Yes" : "No"}
            <Button onClick={() => changePaused()}>{paused ? "Start" : "Pause"}</Button>
          </BtnRow>

          <hr />

          <Col>
            <BtnRow>Current price: 1 USD = {price} XVR Coin</BtnRow>
            <BtnCol>
              <Label>
                1 USD =
                <input
                  value={newPriceUSD}
                  onChange={(e) => { setNewPriceUSD(e.target.value); }}
                  className="text-black"
                  type="number"
                  placeholder={price}
                />
                XVR
              </Label>

              <Button onClick={() => setPrice(newPriceUSD)}>Set new price</Button>
            </BtnCol>
          </Col>

          <hr />

          <BtnCol>
            <BtnRow>Extra tokens for purchases above $1000.</BtnRow>
            <Label>
              <input
                value={extraTokensInput}
                onChange={(e) => { setExtraTokensInput(e.target.value); }}
                className="text-black"
                type="number"
                placeholder={"" + ((extraTokens?.toNumber() || 0) / 100) + "%"}
              />
            </Label>
            <Button
              onClick={() => {
                const num = Math.round(Number(extraTokensInput) * 100);
                if (num > 10000 || num < 0) {
                  alert("Please enter a number between 0 and 100");
                  return;
                }

                if (isNaN(num)) {
                  alert("Please enter a number");
                  return;
                }

                if (extraTokensInput !== (num / 100).toFixed(2).toString()) {
                  alert("Please enter a number with 2 decimal places");
                  return;
                }

                setExtraTokens(num);
              }}
            >
              Set extra tokens
            </Button>
          </BtnCol>
        </Section>
        <ConfigSection />
        <TokenAirdropSection />
        <AffiliateLinkSection />
        <NFTSaleSection />
        <NFTAirdropSection />
        <NFTAffiliateLinkSection />
        <BiAffiliateLinkSection />
      </div>
    </div>
  );
}

export default App;