import { useEthers } from "@usedapp/core";
import { constants, FixedNumber, utils } from "ethers";
import { splitSignature } from "ethers/lib/utils";
import { useState } from "react";
import { callApi } from "../util/api";
import { useOwner } from "../util/tokenConstants";
import AllAffiliateCodes from "./affiliate/AllCodes";
import Button from "./Button";
import { BtnCol, Col, Section } from "./Container";

const AffiliateLinkSection = () => {
  const { library, account } = useEthers();
  const owner = useOwner();

  const [influencerAddress, setInfluencerAddress] = useState("");
  const [salePercentForInfluencer, setSalePercentForInfluencer] = useState("");
  const [extraTokens, setExtraTokens] = useState("");
  const [codeName, setCodeName] = useState("");

  const [code, setCode] = useState("");

  if (account?.toLowerCase() !== owner?.toLowerCase()) {
    return (
      <Section>
        <p className='text-2xl'><strong>XVRcoin - Linki afiliacyjne</strong></p>
        <p className="text-sm">Tylko właściciel może tworzyć linki afiliacyjne</p>
      </Section>
    );
  }

  return (
    <Section>
      <p className='text-2xl'><strong>XVRcoin - Linki afiliacyjne</strong></p>

      <BtnCol>
        Stwórz link afiliacyjny:

        <Col className="items-stretch">
          <BtnCol className="!gap-1">
            <span>Nazwa linku</span>
            <input
              value={codeName}
              onChange={(e) => setCodeName(e.target.value)}
              type="text"
              placeholder={"grzesiek_123"}
              className="text-black w-full text-sm"
            />
          </BtnCol>

          <BtnCol className="!gap-1">
            <span>Adres influencera</span>
            <input
              value={influencerAddress}
              onChange={(e) => setInfluencerAddress(e.target.value)}
              type="text"
              placeholder={constants.AddressZero}
              className="text-black w-full text-sm"
            />
          </BtnCol>

          <BtnCol className="!gap-1">
            <span>Procent sprzedaży dla influencera</span>
            <input
              value={salePercentForInfluencer}
              onChange={(e) => setSalePercentForInfluencer(e.target.value)}
              type="text"
              placeholder={"5"}
              className="text-black w-full text-sm"
            />
          </BtnCol>

          <BtnCol className="!gap-1">
            <span>Procent dodatkowych tokenów dla użytkownika</span>
            <input
              value={extraTokens}
              onChange={(e) => setExtraTokens(e.target.value)}
              type="text"
              placeholder={"5"}
              className="text-black w-full text-sm"
            />
          </BtnCol>
        </Col>

        <Button onClick={async () => {
          setCode("");

          if (!codeName) {
            alert("Nazwa linku jest niepoprawna");
            return;
          }

          if (!utils.isAddress(influencerAddress)) {
            alert("Adres influencera jest niepoprawny");
            return;
          }

          if (!salePercentForInfluencer || isNaN(parseInt(salePercentForInfluencer))) {
            alert("Procent sprzedaży dla influencera jest niepoprawny");
            return;
          }

          if (!extraTokens || isNaN(parseInt(extraTokens))) {
            alert("Procent dodatkowych tokenów dla użytkownika jest niepoprawny");
            return;
          }

          const hundred = FixedNumber.from(100);
          const salePercent10000 = FixedNumber.from(salePercentForInfluencer).mulUnsafe(hundred).toUnsafeFloat();
          const extraTokens10000 = FixedNumber.from(extraTokens).mulUnsafe(hundred).toUnsafeFloat();

          if (Math.round(salePercent10000) !== salePercent10000) {
            alert("Procent sprzedaży dla influencera musi być podany w procentach i mieć maksymalnie 2 miejsca po przecinku");
            return;
          }

          if (Math.round(extraTokens10000) !== extraTokens10000) {
            alert("Procent dodatkowych tokenów dla użytkownika musi być podany w procentach i mieć maksymalnie 2 miejsca po przecinku");
            return;
          }

          const message = `${influencerAddress.toLowerCase()}${salePercent10000.toString().padStart(5, "0")}${extraTokens10000.toString().padStart(5, "0")}`;
          let signature: string;

          try {
            signature = await library?.getSigner().signMessage(message) || "";
          } catch (e) {
            alert("Nie udało się podpisać wiadomości. Sprawdź czy jesteś zalogowany do portfela i czy jest on poprawnie skonfigurowany.");
            return;
          }

          const { r, s, v } = splitSignature(signature);

          const information = {
            name: codeName,
            influencerAddress: influencerAddress.toLowerCase(),
            salePercent10000,
            extraTokens10000,
            r,
            s,
            v,
          };

          try {
            const response = await callApi("/aff_link", "POST", information);
            setCode(response.id);
          } catch (e) {
            alert("Nie udało się stworzyć kodu. " + JSON.stringify(e));
            return;
          }
        }}>
          Stwórz link
        </Button>

        {code && <CodeLink code={code} />}
      </BtnCol>

      <hr />

      <BtnCol>
        Wszystkie linki afiliacyjne:
        <AllAffiliateCodes />
      </BtnCol>
    </Section>
  );
};

export default AffiliateLinkSection;

/**
 * @param code The affiliate code from the backend
 * @returns A friendly link to the affiliate code
 */
export function codeToLink(code: string) {
  return `https://token.xvrprojectsystem.com/t/${code}`;
}

export function CodeLink({ code }: { code: string; }) {
  const link = codeToLink(code);

  return (
    <a rel="noreferrer" target="_blank" href={link} className="font-bold break-all underline text-left">{link}</a>
  );
}