import { useEffect, useState } from "react";
import Button from "./Button";
import { BtnCol, BtnRow, Section } from "./Container";
import { getCodes as getTokenCodes } from "./affiliate/AllCodes";
import { getCodes as getNFTCodes } from "./affiliate/AllNFTCodes";

interface Code {
  id: string,
  influencerAddress: string,
}

async function getBiCodes() {
  const token = await getTokenCodes() as Code[];
  const nft = await getNFTCodes() as Code[];

  // only return codes that have are in both lists
  return token.filter(a => nft.some(b => b.id === a.id && b.influencerAddress === a.influencerAddress));
}

const BiAffiliateLinkSection = () => {
  const [codes, setCodes] = useState<Code[]>([]);
  const [refresh, setRefresh] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getBiCodes().then(setCodes);
  }, [refresh]);

  return (
    <Section>
      <p className='text-2xl'><strong>Podwójne Linki afiliacyjne</strong></p>

      <BtnCol>
        <BtnRow>
          <Button onClick={() => setShow(a => !a)}>{show ? "Zwiń" : "Pokaż"}</Button>
          {show && <Button onClick={() => setRefresh(a => a + 1)}>Odśwież linki</Button>}
        </BtnRow>
        {show && codes.map((code, i) => (
          <BtnCol key={i} className="gap-1 group">
            <p className="text-left">Link: <CodeLink code={code.id} /></p>
            <p>Adres influencera:</p>
            <p className="font-bold break-all text-left">{code.influencerAddress}</p>
            <div className="w-full h-px bg-white mt-4 group-last:hidden" />
          </BtnCol>
        ))}
        {show && codes.length === 0 && <p>Brak linków</p>}
      </BtnCol>
    </Section>
  );
};

export default BiAffiliateLinkSection;

function codeToLink(code: string) {
  return `https://token.xvrprojectsystem.com/bl/${code}`;
}

function CodeLink({ code }: { code: string; }) {
  const link = codeToLink(code);

  return (
    <a rel="noreferrer" target="_blank" href={link} className="font-bold break-all underline text-left">{link}</a>
  );
}