import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { DAppProvider } from '@usedapp/core';
import { usedNetwork } from './util/tokenConstants';
import LoginProvider from './components/LoginProvider';
import LoginBlock from './components/LoginBlock';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <DAppProvider config={{
      readOnlyChainId: usedNetwork.chain.chainId,
      networks: [usedNetwork.chain],
      readOnlyUrls: {
        [usedNetwork.chain.chainId]: usedNetwork.rpc
      }
    }}>
      <LoginProvider>
        <LoginBlock>
          <App />
        </LoginBlock>
      </LoginProvider>
    </DAppProvider>
  </React.StrictMode>
);