import { useEthers } from "@usedapp/core";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { callApi } from "../../util/api";
import { CodeLink } from "../AffiliateLinkSection";
import Button from "../Button";
import { BtnCol, BtnRow } from "../Container";

interface Code {
  id: string,
  influencerAddress: string,
  salePercent10000: number,
  extraTokens10000: number,
  r: string,
  s: string,
  v: number,
  message: string,
}

export default function AllAffiliateCodes() {
  const [codes, setCodes] = useState<Code[]>([]);
  const [refresh, setRefresh] = useState(0);
  const [show, setShow] = useState(false);
  const { library } = useEthers();

  useEffect(() => {
    getCodes().then(setCodes);
  }, [refresh]);

  return (
    <>
      <BtnRow>
        <Button onClick={() => setShow(a => !a)}>{show ? "Zwiń" : "Pokaż"}</Button>
        {show && <Button onClick={() => setRefresh(a => a + 1)}>Odśwież linki</Button>}
      </BtnRow>
      {show && codes.map((code, i) => (
        <BtnCol key={i} className="gap-1 group">
          <p className="text-left">Link: <CodeLink code={code.id} /></p>
          <p>Adres influencera:</p>
          <p className="font-bold break-all text-left">{code.influencerAddress}</p>
          <p>Procent sprzedaży dla influencera:</p>
          <p className="font-bold break-all text-left">{code.salePercent10000 / 100}%</p>
          <p>Procent dodatkowych tokenów dla użytkownika:</p>
          <p className="font-bold break-all text-left">{code.extraTokens10000 / 100}%</p>
          <Button className="ml-auto" onClick={() => deleteCode(code.id, library!).catch(() => alert("Nie udało się usunąć linku")).finally(() => setRefresh(a => a + 1))}>
            Usuń link
          </Button>
          <div className="w-full h-px bg-white mt-4 group-last:hidden" />
        </BtnCol>
      ))}
      {show && codes.length === 0 && <p>Brak linków</p>}
    </>
  );
}

export async function getCodes() {
  const codes = await callApi("/aff_links", "GET");
  return codes;
}

async function deleteCode(code: string, provider: ethers.providers.JsonRpcProvider) {
  const signer = provider.getSigner();
  const message = `Delete affiliate link ${code}`;
  const signature = await signer.signMessage(message);
  const { r, s, v } = ethers.utils.splitSignature(signature);
  await callApi("/aff_link", "DELETE", { id: code, r, s, v });
}